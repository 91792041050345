import React from 'react'
import styled, { css } from 'styled-components'

interface ColumnsProps {
    children: JSX.Element | JSX.Element[]
    asList?: boolean
    layoutOverride?: string
}

const Columns = ({ children, asList = false, layoutOverride = '' }: ColumnsProps): JSX.Element => {
    if (!Array.isArray(children)) {
        children = [children]
    }

    const innerContent = children.map((child, index) => <React.Fragment key={index}>{child}</React.Fragment>)

    const alternativeOverride = children.length === 2 ? 'twoColumns' : ''

    return asList ? (
        <List layoutOverride={layoutOverride} numberOfChildren={children.length}>
            {innerContent}
        </List>
    ) : (
        <Container layoutOverride={layoutOverride ? layoutOverride : alternativeOverride}>{innerContent}</Container>
    )
}

interface StyledColumnsProps {
    layoutOverride?: string
    numberOfChildren?: number
}

const sharedStyles = css`
    max-width: var(--page-width);
    padding: 0 var(--gutter-width);
    margin: 0 auto;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 100%;

    @media (min-width: 45rem) and (max-width: 67rem) {
        /* 
      On tablet widths, center the last block if it's
      the third, or fifth, or seventh, etc. block within the section.
    */
        > :last-child:nth-child(2n + 1):not(:first-child) {
            width: calc((100% - 2rem) / 2);
            grid-column: 1 / span 2;
            margin-left: auto;
            margin-right: auto;
        }
    }
`

const List = styled.ul<StyledColumnsProps>`
    ${sharedStyles}

    list-style: none;

    @media (min-width: 45rem) {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }

    ${props =>
        props.numberOfChildren &&
        props.numberOfChildren > 3 &&
        css`
            @media (min-width: 45rem) {
                grid-template-columns: 50% 50%;
            }

            @media (min-width: 63rem) {
                grid-template-columns: repeat(3, 1fr);
            }
        `}

    ${props =>
        props.layoutOverride === 'cardList' &&
        css`
            grid-gap: 1rem;
        `}

  ${props =>
        props.layoutOverride === 'carGrid' &&
        css`
            grid-gap: 1.25rem;

            @media (min-width: 45rem) {
                grid-template-columns: repeat(auto-fill, minmax(18.375rem, 1fr));
            }
        `}
`

const Container = styled.div<StyledColumnsProps>`
    ${sharedStyles}

    @media (min-width: 45rem) {
        grid-template-columns: repeat(auto-fit, minmax(20rem, auto));
    }

    ${props =>
        props.layoutOverride === 'twoColumns' &&
        css`
            @media (min-width: 60rem) {
                grid-gap: 5rem;
            }
        `}

    ${props =>
        props.layoutOverride === 'wideRight' &&
        css`
            grid-gap: 2rem;

            @media (min-width: 45rem) {
                grid-template-columns: 100%;
            }

            @media (min-width: 55rem) {
                padding-bottom: 4rem;
                grid-template-columns: 1fr 2fr;
                align-items: center;
            }
        `}

  ${props =>
        props.layoutOverride === 'twoEqualColumns' &&
        css`
            grid-gap: 2rem;

            @media (min-width: 45rem) {
                grid-template-columns: 100%;
            }

            @media (min-width: 55rem) {
                grid-template-columns: 1fr 1fr;
            }
        `}

  ${props =>
        props.layoutOverride === 'elementOnImage' &&
        css`
            min-height: 32vw;
            max-width: 100%;
            position: relative;
            padding: 2rem 0 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            > picture {
                height: calc(100% - 8.5rem);
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            > :not(picture) {
                max-width: 34rem;
                position: relative;
                margin: 15rem var(--gutter-width) 0;

                @media (max-width: 51.9375rem) {
                    width: 100%;
                }

                @media (min-width: 52rem) {
                    margin: 8vw var(--gutter-width) 3rem;
                }

                @media (min-width: 83rem) {
                    margin: 8vw calc((100vw - var(--page-width)) / 2) 3rem;
                }
            }
        `}
`

export default Columns
