import { CraftEntry, CraftGlobalSet, LocaleObject } from '../general/types'
import { extractGlobalSet } from './extract-data'
import getLocaleLinks from './get-locale-links'
import { getLocalePrefix, LocaleLink } from '../general/locale'
import getSocialUrls, { SocialUrls } from './get-social-urls'
import handleCraftComponents from './handle-craft-components'
import { ImageData } from '../../components/craft-cms/image'
import { LinkData } from '../../components/core/link'
import processChildComponentName from './process-child-component-name'
import { ShoppingCartLabels } from '../../components/core/shopping-cart'
import shouldRenderAccountButton from './should-render-account-button'
import shouldRenderShoppingCart from './should-render-shopping-cart'

interface PrepareHeaderPropsParam {
    allEntries: CraftEntry[]
    appEntries: CraftEntry[]
    currentEntry: CraftEntry
    currentLocale: LocaleObject
    globalSets: CraftGlobalSet[]
}

export interface MenuItem {
    linkArray: LinkData[]
    displayType: string
    icon: string
    image: ImageData[]
}

export interface ConfiguratorUrls {
    modelS: string | null
    model3: string | null
    modelX: string | null
    modelY: string | null
}

export interface HeaderProps {
    configuratorUrls?: ConfiguratorUrls
    isFullNavBar: boolean
    isNavBarSeeThrough: boolean
    localeLinks: LocaleLink[]
    logoVersion?: string
    menuItems?: MenuItem[]
    renderAccountButton?: boolean
    renderShoppingCart: boolean
    shoppingCartLabels?: ShoppingCartLabels
    socialUrls?: SocialUrls
}

/* 
  Utilities used solely by prepareHeaderProps
*/

function adjustPropsForReady2Share(headerProps: HeaderProps): HeaderProps {
    return {
        ...headerProps,
        logoVersion: 'r2s',
        isNavBarSeeThrough: false,
    }
}

function checkWhetherPageHasHero(currentEntry: CraftEntry): boolean {
    const components = handleCraftComponents(currentEntry)
    const firstChildComponentName = processChildComponentName(components[0]?.children[0])

    if (
        components?.length &&
        components[0].background.startsWith('full') &&
        ['callToActionHero', 'textOnImage', 'banner'].includes(firstChildComponentName)
    ) {
        return true
    }

    return false
}

function checkWhetherPageIsHomepage(currentEntry: CraftEntry): boolean {
    return currentEntry.typeHandle === 'homepage'
}

function getConfiguratorUrls(appEntries: CraftEntry[], currentLocale: LocaleObject): ConfiguratorUrls {
    return ['modelS', 'model3', 'modelX', 'modelY'].reduce(
        (urls: ConfiguratorUrls, modelKey: string) => {
            const appEntry = appEntries.find(
                entry => entry.appType[0].typeHandle === 'configurator' && entry.appType[0].model === modelKey
            )

            if (appEntry) {
                const localePrefix = getLocalePrefix(currentLocale.language)
                urls[modelKey as keyof ConfiguratorUrls] = localePrefix + appEntry.slug.replace(/---/g, '/')
            }

            return urls
        },
        {
            modelS: null,
            model3: null,
            modelX: null,
            modelY: null,
        }
    )
}

/*
  Main function
*/

export default function prepareHeaderProps({
    allEntries,
    appEntries,
    currentEntry,
    currentLocale,
    globalSets,
}: PrepareHeaderPropsParam): HeaderProps {
    const localeLinks = getLocaleLinks({ allEntries, currentEntry, currentLocale })
    const socialUrls = getSocialUrls(globalSets)
    const isFullNavBar = checkWhetherPageIsHomepage(currentEntry)
    const isNavBarSeeThrough = checkWhetherPageHasHero(currentEntry)
    const { menuItems } = extractGlobalSet(globalSets, 'menu')
    const renderAccountButton = shouldRenderAccountButton({ allEntries, currentLocale })
    const configuratorUrls = getConfiguratorUrls(appEntries, currentLocale)
    const renderShoppingCart = shouldRenderShoppingCart({ allEntries, appEntries, currentLocale })
    const shoppingCartLabels = extractGlobalSet(globalSets, 'shoppingCart') as unknown as ShoppingCartLabels

    const headerProps = {
        configuratorUrls,
        isFullNavBar,
        isNavBarSeeThrough,
        menuItems,
        localeLinks,
        renderAccountButton,
        renderShoppingCart,
        shoppingCartLabels,
        socialUrls,
    }

    if (currentLocale.siteType === 'r2s') {
        return adjustPropsForReady2Share(headerProps)
    }

    return headerProps
}
