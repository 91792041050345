import { LinkData } from '../../components/core/link'
import { LocaleLink } from '../general/locale'
import { CraftGlobalSet, CraftEntry, LocaleObject } from '../general/types'
import { extractGlobalSet } from './extract-data'
import getLocaleLinks from './get-locale-links'
import getSocialUrls, { SocialUrls } from './get-social-urls'
import handleCraftComponents from './handle-craft-components'

interface PrepareFooterPropsParam {
    allEntries: CraftEntry[]
    currentEntry: CraftEntry
    currentLocale: LocaleObject
    globalSets: CraftGlobalSet[]
}

interface FooterLink {
    linkArray: LinkData[]
}

interface FooterColumn {
    heading: string
    footerLinks: FooterLink[]
}

interface FooterReviewLink {
    src: string
    imageUrl: string
    alt: string
}

export interface ContactDetailsItem {
    textLabel: string
    value: string
    type: string
}

export interface FooterProps {
    footerBottomLinks?: FooterLink[]
    footerBottomText?: string
    footerLinkColumns?: FooterColumn[]
    footerReviewLink?: FooterReviewLink[]
    socialUrls?: SocialUrls
    contactDetails: ContactDetailsItem[]
    localeLinks: LocaleLink[]
    withTopMargin?: boolean
    useReady2ShareFooter?: boolean
}

/* 
  Utilities used solely by prepareFooterProps
*/

function decideWhetherTopMarginIsNeeded(currentEntry: CraftEntry): boolean {
    const pageComponents = handleCraftComponents(currentEntry)
    return pageComponents.length && pageComponents[pageComponents.length - 1]
        ? pageComponents[pageComponents.length - 1].background === 'defaultBackground'
        : false
}

function getContactDetails(globalSets: CraftGlobalSet[]): ContactDetailsItem[] {
    const { contactDetails } = extractGlobalSet(globalSets, 'sitewideSettings')
    return contactDetails || []
}

/*
  Main function
*/

export default function prepareFooterProps({
    allEntries,
    currentEntry,
    currentLocale,
    globalSets,
}: PrepareFooterPropsParam): FooterProps {
    const { footerBottomText, footerBottomLinks, footerLinkColumns, footerReviewLink } = extractGlobalSet(
        globalSets,
        'footer'
    )

    const localeLinks = getLocaleLinks({ allEntries, currentEntry, currentLocale })
    const socialUrls = getSocialUrls(globalSets)
    const contactDetails = getContactDetails(globalSets)
    const withTopMargin = decideWhetherTopMarginIsNeeded(currentEntry)
    const useReady2ShareFooter = currentLocale.siteType === 'r2s'

    return {
        footerBottomLinks,
        footerBottomText,
        footerLinkColumns,
        footerReviewLink,
        contactDetails,
        socialUrls,
        localeLinks,
        withTopMargin,
        useReady2ShareFooter,
    }
}
