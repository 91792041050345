import { CraftEntry } from '../general/types'

export default function handleCraftComponents(currentEntry: CraftEntry): any[] {
    const components = currentEntry.components || []

    /*
    Mitigate a Craft Neo bug where blocks are occasionally duplicated.
    The duplicates then only contain a __typename property, nothing else.
  */
    const filteredComponents = components.filter((component: any) => Object.keys(component).length > 1)

    return filteredComponents.reduce((componentArray: any[], component: any) => {
        if (component.typeHandle === 'reusableComponent') {
            return component?.selectedComponent?.length && component?.selectedComponent[0].componentType.length
                ? [...componentArray, component.selectedComponent[0].componentType[0]]
                : componentArray
        } else {
            return [...componentArray, component]
        }
    }, [])
}
