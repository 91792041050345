export default function processChildComponentName(childComponent: { remoteTypeName: string }): string {
    if (!childComponent?.remoteTypeName) {
        return ''
    }

    const { remoteTypeName } = childComponent

    if (remoteTypeName.indexOf('_') === -1) {
        return remoteTypeName
    }

    return remoteTypeName.slice(remoteTypeName.indexOf('_') + 1, remoteTypeName.lastIndexOf('_'))
}
