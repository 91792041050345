import { CraftEntry, LocaleObject } from '../general/types'

interface ShouldRenderAccountButtonParam {
    allEntries: CraftEntry[]
    currentLocale: LocaleObject
}

export default function shouldRenderAccountButton({
    allEntries,
    currentLocale,
}: ShouldRenderAccountButtonParam): boolean {
    return allEntries.some(
        (entry: CraftEntry) => entry.url.startsWith(currentLocale.baseUrl) && entry.typeHandle === 'account'
    )
}
