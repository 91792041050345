import { CraftGlobalSet } from '../general/types'
import { extractGlobalSet } from './extract-data'

export interface SocialUrls {
    newsletter: string
    facebook: string
    instagram: string
    twitter: string
    linkedin: string
}

export default function getSocialUrls(globalSets: CraftGlobalSet[]): SocialUrls {
    const sitewideSettings = extractGlobalSet(globalSets, 'sitewideSettings')

    return {
        newsletter: sitewideSettings?.newsletterSignupUrl || '',
        facebook: sitewideSettings?.facebookUrl || '',
        instagram: sitewideSettings?.instagramUrl || '',
        twitter: sitewideSettings?.twitterUrl || '',
        linkedin: sitewideSettings?.linkedinUrl || '',
    }
}
