import { CraftEntry, LocaleObject } from '../general/types'
import shouldRenderAccountButton from './should-render-account-button'

interface ShouldRenderShoppingCartParam {
    allEntries: CraftEntry[]
    appEntries: CraftEntry[]
    currentLocale: LocaleObject
}

export default function shouldRenderShoppingCart({
    allEntries,
    appEntries,
    currentLocale,
}: ShouldRenderShoppingCartParam): boolean {
    if (!shouldRenderAccountButton({ allEntries, currentLocale })) {
        return false
    }

    return appEntries.some(
        (entry: CraftEntry) => entry.appType.length && entry.appType[0].typeHandle === 'configurator'
    )
}
