import { createStyles, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            minWidth: '100%',
        },
        inputLabel: {
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, .54)',
            fontFamily:
                "'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        select: {
            color: 'rgba(0, 0, 0, .7)',
            fontFamily:
                "'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
            fontWeight: 600,

            '&:before': {
                borderBottom: '1px solid #333',
            },

            '&:after': {
                borderBottom: '1px solid #51BBA0',
            },

            '&:active': {
                borderBottom: '1px solid #51BBA0',
            },

            '&:focus': {
                backgroundColor: 'transparent',
                borderBottom: '1px solid #51BBA0',
            },

            '&:hover:not(.Mui-disabled):not(.Mui-error):before': {
                borderBottom: '1px solid #51BBA0',
            },
        },
    })
)

type SelectValue = string | number

interface Selectable {
    name: string
    value: SelectValue
}

interface MaterialSelectProps {
    currentValue: SelectValue
    label: string
    onChange(arg: string): void
    selectables: Selectable[]
}

const MaterialSelect = ({ currentValue, label, onChange, selectables }: MaterialSelectProps): JSX.Element => {
    const classes = useStyles()

    const uid = Math.ceil(Math.random() * 1000)
    const id = `car_shop_select_${uid}`
    const labelId = `car_shop_select_label_${uid}`

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={labelId} className={classes.inputLabel}>
                {label}
            </InputLabel>
            <Select
                labelId={labelId}
                id={id}
                className={classes.select}
                value={currentValue}
                onChange={event => onChange(event?.target.value as string)}
            >
                {selectables.map((selectable, index) => (
                    <MenuItem key={index} value={selectable.value}>
                        {selectable.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MaterialSelect
