import { CraftGlobalSet, CraftEntry, LocaleObject } from '../general/types'
import prepareFooterProps, { FooterProps } from './prepare-footer-props'
import prepareHeaderProps, { HeaderProps } from './prepare-header-props'
import prepareSeoProps, { SeoProps } from './prepare-seo-props'

export interface PreparePageDataParam {
    allEntries: CraftEntry[]
    appEntries: CraftEntry[]
    cookieConsent: string
    currentEntry: CraftEntry
    currentLocale: LocaleObject
    environment: string
    globalSets: CraftGlobalSet[]
}

interface PageData {
    footerProps: FooterProps
    headerProps: HeaderProps
    seoProps: SeoProps
}

export default function preparePageData({
    allEntries,
    appEntries,
    cookieConsent,
    currentEntry,
    currentLocale,
    environment,
    globalSets,
}: PreparePageDataParam): PageData {
    return {
        footerProps: prepareFooterProps({ allEntries, currentEntry, currentLocale, globalSets }),
        headerProps: prepareHeaderProps({ allEntries, appEntries, currentEntry, currentLocale, globalSets }),
        seoProps: prepareSeoProps({ allEntries, cookieConsent, currentEntry, currentLocale, environment, globalSets }),
    }
}
