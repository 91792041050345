import { getLocaleString } from './locale'
import { LocaleObject } from './types'

interface FormatWithCurrencySymbolOptions {
    displayAsFloat?: boolean
    hardcodeCurrency?: string
}

export default function formatWithCurrencySymbol(
    value: number,
    locale: LocaleObject | string,
    options?: FormatWithCurrencySymbolOptions
): string {
    const intlCurrencyFormatOptions: Intl.NumberFormatOptions = {}

    if (!options?.hardcodeCurrency && typeof locale === 'string') {
        console.warn("Can't format as currency if no currency is hardcoded and the full locale object is not given")
    } else {
        intlCurrencyFormatOptions.style = 'currency'
        intlCurrencyFormatOptions.currency = options?.hardcodeCurrency
            ? options.hardcodeCurrency
            : (locale as LocaleObject).currencyCode
    }

    if (!options?.displayAsFloat) {
        intlCurrencyFormatOptions.minimumFractionDigits = 0
        intlCurrencyFormatOptions.maximumFractionDigits = 0
    }

    const localeString = typeof locale === 'string' ? locale : getLocaleString(locale)

    return Intl.NumberFormat(localeString, intlCurrencyFormatOptions).format(value)
}
