import { getLocaleSlug, LocaleLink } from '../general/locale'
import { MisterGreenConfig, LocaleObject, CraftEntry } from '../general/types'
import misterGreenConfig from '../../../mistergreen-config'

const { locales }: MisterGreenConfig = misterGreenConfig

export default function getLocaleLinks({
    allEntries,
    currentEntry,
    currentLocale,
}: {
    allEntries: CraftEntry[]
    currentEntry: CraftEntry
    currentLocale: LocaleObject
}): LocaleLink[] {
    return allEntries.reduce((accumulator: LocaleLink[], entry: CraftEntry, index: number) => {
        if (entry.sourceId !== currentEntry.sourceId) {
            return accumulator
        }

        if (!locales.filter(locale => entry.url.startsWith(locale?.baseUrl)).length) {
            return accumulator
        }

        const entryLocale = locales.find(locale => entry.url.startsWith(locale?.baseUrl))

        return currentLocale && entryLocale
            ? [
                  ...accumulator,
                  {
                      language: entryLocale.language,
                      languagePrettyName: entryLocale.languagePrettyName,
                      slug: getLocaleSlug(entry, entryLocale.language),
                      id: index,
                      isCurrent: entry.url.startsWith(currentLocale?.baseUrl),
                  },
              ]
            : accumulator
    }, [])
}
