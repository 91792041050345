import { CraftEntry, LocaleObject, CraftGlobalSet, MisterGreenConfig } from '../general/types'
import { extractGlobalSet } from './extract-data'
import { getCurrentImageUrl, getLocaleString } from '../general/locale'
import { getImageName } from '../general/image'
import handleCraftComponents from './handle-craft-components'
import misterGreenConfig from '../../../mistergreen-config'

const { allLocales }: MisterGreenConfig = misterGreenConfig

interface PrepareSeoPropsParam {
    allEntries: CraftEntry[]
    cookieConsent: string
    currentEntry: CraftEntry
    currentLocale: LocaleObject
    environment: string
    globalSets: CraftGlobalSet[]
}

interface PageLinkObject {
    rel: string
    href: string
    hreflang?: string
}

interface SeoImage {
    filename: string
    dateUpdated: string
}

export interface SeoProps {
    description: string
    lang?: string
    title: string
    author: string
    pageLinks?: PageLinkObject[]
    image?: string
    noindex?: boolean
    keywords?: string[]
    cookieConsent?: string
    environment?: string
    hasFormOnPage?: boolean
}

/* 
  Utilities used solely by prepareSeoProps
*/

function checkWhetherPageHasForm(currentEntry: CraftEntry) {
    return handleCraftComponents(currentEntry).some((component: any) => {
        if (component.children.length) {
            return component.children.some(({ typeHandle }: any) => typeHandle === 'form')
        }

        return false
    })
}

function getAlternateEntries(allEntries: CraftEntry[], currentEntry: CraftEntry): PageLinkObject[] {
    const pageEntries = allEntries.filter(entry => entry.sourceId === currentEntry.sourceId)

    return pageEntries.length > 1
        ? [
              { rel: 'canonical', href: currentEntry.url },
              ...pageEntries.reduce((accumulator: PageLinkObject[], entry: CraftEntry) => {
                  const entryLocale = allLocales.find((locale: LocaleObject) => entry.url.startsWith(locale?.baseUrl))

                  if (entryLocale) {
                      return [
                          ...accumulator,
                          {
                              rel: 'alternate',
                              href: entry.url,
                              hreflang: getLocaleString(entryLocale),
                          },
                      ]
                  } else {
                      return accumulator
                  }
              }, []),
          ]
        : []
}

function getSeoImage(seoImage: SeoImage[], defaultImage: SeoImage[]) {
    const imageUrl = getCurrentImageUrl()

    if (!defaultImage[0]) {
        throw new Error('Please provide a default image')
    }

    return seoImage?.length && seoImage[0].filename
        ? `${imageUrl}/${getImageName(seoImage[0].filename)}_large_1x.jpeg?v=${seoImage[0].dateUpdated}`
        : `${imageUrl}/${getImageName(defaultImage[0].filename)}_large_1x.jpeg?v=${defaultImage[0].dateUpdated}`
}

/*
  Main function
*/

export default function prepareSeoProps({
    allEntries,
    cookieConsent,
    currentEntry,
    currentLocale,
    environment,
    globalSets,
}: PrepareSeoPropsParam): SeoProps {
    const { defaultTitle, defaultDescription, defaultAuthor, defaultImage } = extractGlobalSet(globalSets, 'seo')
    const { typeHandle, title, seoTitle, description, seoImage, noindex, keywords } = currentEntry
    const image = getSeoImage(seoImage, defaultImage)
    const alternateEntries = getAlternateEntries(allEntries, currentEntry)
    const hasFormOnPage = checkWhetherPageHasForm(currentEntry)

    return {
        author: defaultAuthor,
        title: typeHandle !== 'homepage' ? `${seoTitle ? seoTitle : title} | ${defaultTitle}` : defaultTitle,
        lang: getLocaleString(currentLocale),
        description: description || defaultDescription,
        image,
        pageLinks: alternateEntries,
        noindex: noindex === 'true',
        keywords: (keywords || []).map(({ keyword }: { keyword: string }) => keyword),
        cookieConsent,
        environment,
        hasFormOnPage,
    }
}
