import { Locale } from '../../utilities/general/locale'
import { LocaleObject } from '../../utilities/general/types'
import React, { useState } from 'react'
import Rollbar from 'rollbar'

interface OuterWrapperProps {
    children: JSX.Element | JSX.Element[]
    currentLocale: LocaleObject
}

const OuterWrapper = ({ children, currentLocale }: OuterWrapperProps): JSX.Element => {
    useState({
        rollbar: new Rollbar({
            accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
            captureIp: 'anonymize',
            hostWhiteList: ['mistergreen.nl', 'mistergreenlease.be'],
            payload: {
                environment: 'production',
            },
        }),
    })

    return <Locale.Provider value={currentLocale}>{children}</Locale.Provider>
}

export default OuterWrapper
