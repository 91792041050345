import { CraftGlobalSet, CraftData, CraftEntry } from '../general/types'

export function extractGlobalSet(globalSets: CraftGlobalSet[], setHandle: string): CraftGlobalSet {
    const globalSet = globalSets.find((set: CraftGlobalSet) => set.handle === setHandle)

    if (!globalSet) {
        throw new Error(`Global set ${setHandle} could not be found.`)
    }

    return globalSet
}

export function extractCurrentEntry(craftData: CraftData): CraftEntry {
    return craftData.allCraftEntryInterface.nodes[0]
}
